import React from 'react';
import Div100vh from 'react-div-100vh';
const IndexPage = () => {
  return (
    <Div100vh>
      <div className=' flex items-center h-full'>
        <img src='/logo.png' alt='Fragile studio' className='m-auto w-1/6' />
      </div>
    </Div100vh>
  );
};

export default IndexPage;
